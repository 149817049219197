var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("el-input", {
              staticClass: "filter-item top_search_css",
              attrs: {
                "prefix-icon": "el-icon-search",
                size: "mini",
                placeholder: "请输入搜索内容",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
              model: {
                value: _vm.listQuery.key,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.listQuery,
                    "key",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "listQuery.key",
              },
            }),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-search",
                  clearable: "",
                },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("搜索")]
            ),
            _c("permission-btn", {
              attrs: { moduleName: "modulemanager", size: "mini" },
              on: { "btn-event": _vm.onBtnClicked },
            }),
            _c(
              "el-checkbox",
              {
                staticClass: "m-l-15",
                attrs: { size: "mini" },
                on: {
                  change: function ($event) {
                    _vm.tableKey = _vm.tableKey + 1
                  },
                },
                model: {
                  value: _vm.showDescription,
                  callback: function ($$v) {
                    _vm.showDescription = $$v
                  },
                  expression: "showDescription",
                },
              },
              [_vm._v("Id/描述 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-row",
            { staticClass: "fh", attrs: { gutter: 8 } },
            [
              _c(
                "el-col",
                { staticClass: "fh ls-border", attrs: { span: 10 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "card-body-none fh",
                      staticStyle: { "overflow-y": "auto" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { padding: "0 11px" },
                              attrs: { type: "text" },
                              on: { click: _vm.getAllMenus },
                            },
                            [_vm._v("所有菜单>>")]
                          ),
                        ],
                        1
                      ),
                      _c("tree-table", {
                        ref: "menuTable",
                        attrs: {
                          "highlight-current-row": "",
                          data: _vm.modulesTree,
                          columns: _vm.columns,
                          border: "",
                        },
                        on: { "row-click": _vm.treeClick },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { staticClass: "fh", attrs: { span: 14 } }, [
                _c(
                  "div",
                  { staticClass: "bg-white fh" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        key: _vm.tableKey,
                        ref: "mainTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.currentPageMenus,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                          height: "calc(100% - 52px)",
                        },
                        on: {
                          "row-click": _vm.rowClick,
                          "selection-change": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            align: "center",
                            width: "55",
                          },
                        }),
                        _vm.showDescription
                          ? _c("el-table-column", {
                              attrs: {
                                label: "Id",
                                "min-width": "120px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.id)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                773642443
                              ),
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "80px",
                            label: "DOM ID",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-type",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditMenu(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.domId))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "50px",
                            label: "名称",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.name))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "30px",
                            label: "排序",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.sort))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "80px",
                            label: "样式",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.class))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": "80px",
                            label: "ICON",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.icon))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.showDescription
                          ? _c("el-table-column", {
                              attrs: {
                                label: "描述",
                                "min-width": "120px",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.remark)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                186672324
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total>0",
                        },
                      ],
                      attrs: {
                        total: _vm.total,
                        page: _vm.listQuery.page,
                        limit: _vm.listQuery.limit,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.listQuery, "page", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.listQuery, "limit", $event)
                        },
                        pagination: _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini",
              attrs: {
                width: "500px",
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.temp,
                    "label-position": "right",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogStatus == "update",
                          expression: "dialogStatus=='update'",
                        },
                      ],
                      attrs: { size: "small", label: "Id", prop: "id" },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.temp.id))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogStatus == "update",
                          expression: "dialogStatus=='update'",
                        },
                      ],
                      attrs: { size: "small", label: "层级ID" },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.temp.cascadeId))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.temp.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "name", $$v)
                          },
                          expression: "temp.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "排序" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 20 },
                        model: {
                          value: _vm.temp.sortNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "sortNo", $$v)
                          },
                          expression: "temp.sortNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        size: "small",
                        label: "是否系统",
                        prop: "isSys",
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.temp.isSys,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "isSys", $$v)
                          },
                          expression: "temp.isSys",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "模块标识" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.temp.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "code", $$v)
                          },
                          expression: "temp.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "图标" } },
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "popoverModule",
                          attrs: {
                            placement: "right",
                            width: "500",
                            trigger: "click",
                            content: "",
                          },
                          on: {
                            show: function ($event) {
                              _vm.iconDataListIndex = 0
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            class: _vm.temp.iconName
                              ? `iconfont icon-${_vm.temp.iconName} custom-icon-input`
                              : "",
                            staticStyle: { "line-height": "32px" },
                            attrs: { slot: "reference" },
                            slot: "reference",
                            model: {
                              value: _vm.iconNanes,
                              callback: function ($$v) {
                                _vm.iconNanes = $$v
                              },
                              expression: "iconNanes",
                            },
                          }),
                          _c(
                            "el-row",
                            { staticClass: "selectIcon-box" },
                            _vm._l(
                              _vm.iconDataList[_vm.iconDataListIndex],
                              function (item, index) {
                                return _c(
                                  "el-col",
                                  {
                                    key: index,
                                    class: {
                                      active:
                                        _vm.temp.iconName === item.font_class,
                                    },
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: item.name,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          class: `${_vm.iconData.font_family} ${_vm.iconData.css_prefix_text}${item.font_class}`,
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleChangeTempIcon(
                                                item
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "pages_w",
                              staticStyle: { "text-align": "center" },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "page_box",
                                  staticStyle: { display: "flex" },
                                  attrs: { span: 12 },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.iconDataListIndex == 0
                                            ? true
                                            : false,
                                      },
                                      on: { click: _vm.leftIconDataListData },
                                    },
                                    [_vm._v("上一页")]
                                  ),
                                  _vm._l(
                                    _vm.iconDataList,
                                    function (item, index) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: index,
                                            class: {
                                              btn_click: true,
                                              text_color:
                                                index == _vm.iconDataListIndex,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.switchIconDataListData(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(index + 1))]
                                        ),
                                      ]
                                    }
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.iconDataListIndex ==
                                          _vm.iconDataList.length - 1
                                            ? true
                                            : false,
                                      },
                                      on: { click: _vm.rightIconDataListData },
                                    },
                                    [_vm._v("下一页")]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "url" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.temp.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "url", $$v)
                          },
                          expression: "temp.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "mini", label: "上级机构" } },
                    [
                      _c("treeselect", {
                        ref: "modulesTree",
                        attrs: {
                          normalizer: _vm.normalizer,
                          disabled: _vm.treeDisabled,
                          options: _vm.modulesTreeRoot,
                          "default-expand-level": 3,
                          multiple: false,
                          "open-on-click": true,
                          "open-on-focus": true,
                          "clear-on-select": true,
                        },
                        model: {
                          value: _vm.dpSelectModule,
                          callback: function ($$v) {
                            _vm.dpSelectModule = $$v
                          },
                          expression: "dpSelectModule",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.dialogStatus == "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("确认")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.updateData },
                        },
                        [_vm._v("确认")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini",
              attrs: {
                width: "500px",
                title: _vm.textMap[_vm.dialogMenuStatus],
                visible: _vm.dialogMenuVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogMenuVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "menuForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.menuTemp,
                    "label-position": "right",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogMenuStatus == "update",
                          expression: "dialogMenuStatus=='update'",
                        },
                      ],
                      attrs: { size: "small", label: "Id", prop: "id" },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.menuTemp.id))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.menuTemp.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.menuTemp, "name", $$v)
                          },
                          expression: "menuTemp.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "DOM ID" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", "show-word-limit": "" },
                        model: {
                          value: _vm.menuTemp.domId,
                          callback: function ($$v) {
                            _vm.$set(_vm.menuTemp, "domId", $$v)
                          },
                          expression: "menuTemp.domId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { position: "relative" },
                      attrs: { size: "small", label: "样式" },
                    },
                    [
                      _c("el-button", {
                        staticClass: "custom-select-icon",
                        attrs: { type: _vm.menuTemp.class, size: "mini" },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.menuTemp.class,
                            callback: function ($$v) {
                              _vm.$set(_vm.menuTemp, "class", $$v)
                            },
                            expression: "menuTemp.class",
                          },
                        },
                        _vm._l(_vm.classBtns, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.value,
                              attrs: { label: item.value, value: item.value },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.value)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: { type: item.value, size: "mini" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "图标" } },
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "popoverMenu",
                          attrs: {
                            placement: "right",
                            width: "500",
                            trigger: "click",
                          },
                          on: {
                            show: function ($event) {
                              _vm.iconDataListIndex = 0
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            class: _vm.menuTemp.icon
                              ? `iconfont icon-${_vm.menuTemp.icon} custom-icon-input`
                              : "",
                            attrs: { slot: "reference" },
                            slot: "reference",
                            model: {
                              value: _vm.iconNaness,
                              callback: function ($$v) {
                                _vm.iconNaness = $$v
                              },
                              expression: "iconNaness",
                            },
                          }),
                          _c(
                            "el-row",
                            { staticClass: "selectIcon-box" },
                            _vm._l(
                              _vm.iconDataList[_vm.iconDataListIndex],
                              function (item, index) {
                                return _c(
                                  "el-col",
                                  {
                                    key: index,
                                    class: {
                                      active:
                                        _vm.menuTemp.icon === item.font_class,
                                    },
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: item.name,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          class: `${_vm.iconData.font_family} ${_vm.iconData.css_prefix_text}${item.font_class}`,
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleChangeMenuTempIcon(
                                                item
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "pages_w",
                              staticStyle: { "text-align": "center" },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "page_box",
                                  staticStyle: { display: "flex" },
                                  attrs: { span: 12 },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.iconDataListIndex == 0
                                            ? true
                                            : false,
                                      },
                                      on: { click: _vm.leftIconDataListData },
                                    },
                                    [_vm._v("上一页")]
                                  ),
                                  _vm._l(
                                    _vm.iconDataList,
                                    function (item, index) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: index,
                                            class: {
                                              btn_click: true,
                                              text_color:
                                                index == _vm.iconDataListIndex,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.switchIconDataListData(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(index + 1))]
                                        ),
                                      ]
                                    }
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.iconDataListIndex ==
                                          _vm.iconDataList.length - 1
                                            ? true
                                            : false,
                                      },
                                      on: { click: _vm.rightIconDataListData },
                                    },
                                    [_vm._v("下一页")]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "排序" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 20 },
                        model: {
                          value: _vm.menuTemp.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.menuTemp, "sort", $$v)
                          },
                          expression: "menuTemp.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "所属模块" } },
                    [
                      _c("treeselect", {
                        ref: "menuModulesTree",
                        attrs: {
                          normalizer: _vm.normalizer,
                          options: _vm.modulesTree,
                          "default-expand-level": 3,
                          multiple: false,
                          "open-on-click": true,
                          "open-on-focus": true,
                          "clear-on-select": true,
                        },
                        model: {
                          value: _vm.menuTemp.moduleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.menuTemp, "moduleId", $$v)
                          },
                          expression: "menuTemp.moduleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "250",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.menuTemp.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.menuTemp, "remark", $$v)
                          },
                          expression: "menuTemp.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.dialogMenuVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.dialogMenuStatus == "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.addMenu },
                        },
                        [_vm._v("确认")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.updateMenu },
                        },
                        [_vm._v("确认")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }